import AllocationChart from './AllocationChart';
import BenchmarkToggle from './BenchmarkToggle';
import PerformanceChart from './PerformanceChart';
import RiskMetricsChart from './RiskMetricsChart';
import StateBoxes from './StatBoxes';

const DashboardBody: React.FC = () => {
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2">
            <StateBoxes />
          </div>
          <div className="flex w-full md:w-1/2 lg:pl-5 ">
            <AllocationChart />
          </div>
        </div>
        <div className="w-full md:w-1/2">
          <BenchmarkToggle />
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-1/2">
            <PerformanceChart />
          </div>
          <div className="flex  w-full md:w-1/2 lg:pl-5 ">
            <RiskMetricsChart />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardBody;
