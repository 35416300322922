import { PropsWithChildren } from 'react';

import React from 'react';
import Logo from './Logo';
import DesktopSideBar from './DesktopSideBar';
import MobileSideBar from './MobileSideBar';
import type { SidebarNavigation } from '../App';

interface AppLayoutProps {
  sidebarNavigationList: SidebarNavigation[];
}

const AppLayout: React.FC<PropsWithChildren<AppLayoutProps>> = ({
  children,
  sidebarNavigationList,
}) => {
  return (
    <>
      <div>
        <MobileSideBar sidebarNavigationList={sidebarNavigationList}></MobileSideBar>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-48 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col overflow-y-auto bg-gray-800 px-6">
            <div className="flex h-32 w-full shrink-0 items-center justify-center">
              <div className="flex flex-row items-center space-x-2">
                <div>
                  <Logo />
                </div>
                <p className="text-3xl font-bold text-white">Playlist</p>
              </div>
            </div>
            <nav className="flex flex-1 flex-col">
              <DesktopSideBar sidebarNavigationList={sidebarNavigationList}></DesktopSideBar>
            </nav>
          </div>
        </div>

        <main className="py-10 lg:pl-48">
          <div className="px-4 text-white sm:px-3 lg:px-5">{children}</div>
        </main>
      </div>
    </>
  );
};

export default AppLayout;
