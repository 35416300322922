import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllocationChartDto, AllocationChartResponseDto } from '../api/ncis-api/api';
import { addressPortfoliosApi, fundPortfoliosApi } from '../api/api';
import { AxiosResponse } from 'axios';
import { Status } from './statBoxesSlice';
import { accountTypes, AccountTypes } from '../utils/types';

export interface AllocationChartState {
  status: Status;
  error: any | null;
  data: Array<AllocationChartDto>;
}
export const assetColor = {
  bitcoin: '#FF9933',
  ethereum: '#0083FF',
} as const;

export const assetColorCode = {
  BTC: '#FF9933',
  ETH: '#0083FF',
  SOL: '#007500',
  AAVE: '#800080',
  AVAX: '#E0115F',
  XRP: '#DAA520',
  MKR: '#56a35d',
  MATIC: '#057175',
  BCH: '#8dc351',
  EOS: '#ffffff',
  STRK: '#888ecf',
  OTHER: '#737373',
} as const;

export type AssetColorCode = typeof assetColorCode;

export type AssetColor = typeof assetColor;

export const assetAbbreviation = {
  bitcoin: 'BTC',
  ethereum: 'ETH',
} as const;

const initialState: AllocationChartState = {
  status: 'idle',
  error: null,
  data: [
    {
      totalAssetsBalanceAud: 0,
      totalAssetsBalanceUsd: 0,
      assetBalances: [
        {
          name: '',
          symbol: '',
          amount: 0,
          amountAud: 0,
          amountUsd: 0,
        },
      ],
    },
  ],
};

export function fetchAllocationChart(accountType: AccountTypes[keyof AccountTypes]) {
  return new Promise<AllocationChartDto[]>(async (resolve, reject) => {
    try {
      // const filteredArray = await new Promise<AllocationChartDto[]>((innerResolve) => {
      //   setTimeout(() => {
      //     innerResolve(allocationChart);
      //   }, 500);
      // });
      const responseData = [];
      if (accountType === 'DEMO') {
        let response: AxiosResponse<AllocationChartResponseDto, any> =
          await addressPortfoliosApi.getAddressAllocationChart(
            'BITCOIN',
            '36GEcFJPXdpLmqPEHMuJHaDi8mWxAuDopr',
          );
        responseData.push(response.data.data);
        response = await addressPortfoliosApi.getAddressAllocationChart(
          'ETHEREUM',
          '0xb1f046d83d9e4a9e844b703795b9ec6c93917a70',
        );

        responseData.push(response.data.data);
        const filteredArray = responseData.filter((item): item is AllocationChartDto => !!item);

        resolve(filteredArray);
      } else {
        let response: AxiosResponse<AllocationChartResponseDto, any> =
          await fundPortfoliosApi.getFundAllocationChart(accountTypes['DigitalX Crypto Fund']);
        responseData.push(response.data.data);
        const filteredArray = responseData.filter((item): item is AllocationChartDto => !!item);
        resolve(filteredArray);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export const setAllocationChartAsync = createAsyncThunk(
  'dashboard/allocation-chart',
  async (accountType: AccountTypes[keyof AccountTypes]) => {
    const response = await fetchAllocationChart(accountType);
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const allocationChartSlice = createSlice({
  name: 'allocation-chart',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setAllocationChartAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        setAllocationChartAsync.fulfilled,
        (state, action: PayloadAction<AllocationChartDto[]>) => {
          state.status = 'idle';
          if (action.payload !== undefined) {
            state.data = action.payload;
          }
        },
      )
      .addCase(setAllocationChartAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

// export const {} = allocationChartSlice.actions;

export default allocationChartSlice.reducer;
