import React from 'react';
interface LoaderProps {
  height?: string;
  circleDiameter?: string;
  circleColor?: string;
  adjustAlinement?: boolean;
}

const Loader: React.FC<LoaderProps> = ({
  height = 'h-64',
  circleDiameter = 'h-4 w-4',
  circleColor = 'bg-primary',
  adjustAlinement = true,
}) => {
  const getLoader = () => {
    return (
      <div className="flex space-x-2">
        <div className={`${circleDiameter} animate-pulse rounded-full ${circleColor}`}></div>
        <div className={`${circleDiameter} animate-pulse rounded-full ${circleColor}`}></div>
        <div className={`${circleDiameter} animate-pulse rounded-full ${circleColor}`}></div>
        <div className={`${circleDiameter} animate-pulse rounded-full ${circleColor}`}></div>
      </div>
    );
  };

  if (adjustAlinement) {
    return <div className={`flex ${height} items-center justify-center`}>{getLoader()}</div>;
  }
  return <>{getLoader()}</>;
};

export default Loader;
