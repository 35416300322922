import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  RiskMetricsChartDto,
  RiskMetricsChartResponseDto,
  TimeSpanType,
} from '../api/ncis-api/api';
import { addressPortfoliosApi, fundPortfoliosApi } from '../api/api';
import { AxiosResponse } from 'axios';
import { Status } from './statBoxesSlice';
import { accountTypes, AccountTypes } from '../utils/types';

export interface RiskMetricsChartState {
  status: Status;
  error: any | null;
  data: Array<RiskMetricsChartDto>;
}

const initialState: RiskMetricsChartState = {
  status: 'idle',
  error: null,
  data: [
    {
      name: '',
      symbol: '',
      monthlyReturnSd: 0,
      monthlyReturnPercentage: 0,
    },
  ],
};

export function fetchRiskMetricsChart(
  accountType: AccountTypes[keyof AccountTypes],
  timePeriod: TimeSpanType,
) {
  return new Promise<RiskMetricsChartDto[]>(async (resolve, reject) => {
    try {
      // const filteredArray = await new Promise<RiskMetricsChartDto[]>((innerResolve) => {
      //   setTimeout(() => {
      //     innerResolve(riskMetricsChart);
      //   }, 500);
      // });
      const responseData: RiskMetricsChartDto[] = [];

      if (accountType === 'DEMO') {
        let response: AxiosResponse<RiskMetricsChartResponseDto, any> =
          await addressPortfoliosApi.getAddressRiskMetricsChart(
            'BITCOIN',
            '36GEcFJPXdpLmqPEHMuJHaDi8mWxAuDopr',
            timePeriod,
          );

        let riskPointArray = response.data.data;
        if (riskPointArray?.length) {
          responseData.push(riskPointArray[0] as RiskMetricsChartDto);
        }

        response = await addressPortfoliosApi.getAddressRiskMetricsChart(
          'ETHEREUM',
          '0xb1f046d83d9e4a9e844b703795b9ec6c93917a70',
          timePeriod,
        );

        riskPointArray = response.data.data;
        if (riskPointArray?.length) {
          responseData.push(riskPointArray[0] as RiskMetricsChartDto);
        }

        const filteredArray = responseData.filter((item): item is RiskMetricsChartDto => !!item);

        resolve(filteredArray);
      } else {
        let response = await fundPortfoliosApi.getFundRiskMetricsChart(
          accountTypes['DigitalX Crypto Fund'],
          timePeriod,
        );

        let riskPointArray = response.data.data;
        if (riskPointArray?.length) {
          responseData.push(riskPointArray as RiskMetricsChartDto);
        }

        const filteredArray = responseData.filter((item): item is RiskMetricsChartDto => !!item);

        resolve(filteredArray);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export const setRiskMetricsChartAsync = createAsyncThunk(
  'dashboard/risk-metrics-chart',
  async (request: { accountType: AccountTypes[keyof AccountTypes]; timePeriod: TimeSpanType }) => {
    const response = await fetchRiskMetricsChart(request.accountType, request.timePeriod);
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const riskMetricsChartSlice = createSlice({
  name: 'risk-metrics-chart',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setRiskMetricsChartAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        setRiskMetricsChartAsync.fulfilled,
        (state, action: PayloadAction<RiskMetricsChartDto[]>) => {
          state.status = 'idle';
          if (action.payload !== undefined) {
            state.data = action.payload;
          }
        },
      )
      .addCase(setRiskMetricsChartAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

// export const {} = riskMetricsChartSlice.actions;

export default riskMetricsChartSlice.reducer;
