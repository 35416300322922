import React from 'react';
import type { SidebarNavigation } from '../App';
import { NavLink } from 'react-router-dom';
import { classNames } from '../utils/helpers';
interface SideBarProps {
  sidebarNavigationList: SidebarNavigation[];
}

const DesktopSideBar: React.FC<SideBarProps> = ({ sidebarNavigationList }) => {
  return (
    <>
      <ul className="flex flex-1 flex-col gap-y-7">
        <li>
          <ul className="-mx-4 space-y-1">
            {sidebarNavigationList.map((item) => (
              <li key={item.name}>
                <NavLink to={item.path}>
                  {({ isActive }) => (
                    <div
                      className={classNames(
                        isActive
                          ? 'bg-primary text-black'
                          : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'group flex h-12 items-center gap-x-3 rounded-md p-2 text-base font-medium capitalize',
                      )}
                    >
                      <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                      {item.name}
                    </div>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </>
  );
};

export default DesktopSideBar;
