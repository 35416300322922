import { useState } from 'react';
import { Switch } from '@headlessui/react';
import { classNames } from '../../utils/helpers';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';

export default function BenchmarkToggle() {
  const [enabled, setEnabled] = useState(false);

  return (
    <div className="flex justify-end pt-6 pr-5">
    <div className="flex flex-col items-center justify-center">
      <span className="inline-flex items-center space-x-1">
      <span className="py-1">Show Benchmark</span> <QuestionMarkCircleIcon className="h-4 w-4" />
      </span>
      
      <Switch
        checked={enabled}
        onChange={setEnabled}
        className={classNames(
          enabled ? 'bg-primary' : 'bg-gray-400',
          'relative inline-flex h-6 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-6' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
          )}
        />
      </Switch>
    </div>
    </div>
  );
}
