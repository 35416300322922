import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  ResponsiveContainer,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Scatter,
} from 'recharts';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setRiskMetricsChartAsync } from '../../state-slices/riskMetricsChartSlice';
import { RiskMetricsChartDto } from '../../api/ncis-api';
import { AssetColorCode, assetColorCode } from '../../state-slices/allocationChartSlice';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { DashboardContext } from './Dashboard';
import { selectTimeSpan } from '../../state-slices/performanceChartSlice';

const RiskMetricsChart = (): ReactElement => {
  const dispatch = useAppDispatch();
  const timeSpan = useAppSelector(selectTimeSpan);
  const { accountType } = useContext(DashboardContext);

  const riskMetricsChartData = useSelector((state: RootState) => {
    return state.dashboard.riskMetricsChart;
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accountType, timeSpan]);

  const getData = async () => {
    dispatch(setRiskMetricsChartAsync({accountType, timePeriod: timeSpan}));
  };

  const [riskMetricsChart, setRiskMetricsChart] = useState<RiskMetricsChartDto[]>([]);

  const makeResultForDemoAccount = () => {
    const newRiskMetricsChartData: RiskMetricsChartDto[] = riskMetricsChartData.data;
    setRiskMetricsChart(newRiskMetricsChartData);
  };

  const makeResultForCryptoFund = () => {
    const newRiskMetricsChartData: RiskMetricsChartDto[] =
      riskMetricsChartData.data.flat() as RiskMetricsChartDto[];
    setRiskMetricsChart(newRiskMetricsChartData);
  };

  useEffect(() => {
    switch (accountType) {
      case 'DEMO':
        makeResultForDemoAccount();
        break;
      case 'CRYPTO_FUND':
        makeResultForCryptoFund();
        break;
      default:
        makeResultForCryptoFund();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskMetricsChartData]);

  const renderColorfulLegendText = (value: string, entry: any) => {
    const { color } = entry;
    return (
      <span style={{ color }} className="px-1 py-0 text-sm">
        {value}
      </span>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const symbol: keyof AssetColorCode = payload[0].payload.symbol;
      const color = assetColorCode[symbol];
      return (
        <div className=" text-sx grid grid-cols-1 justify-items-start gap-1 rounded-lg border border-gray-500 bg-gray-800 p-3 opacity-90">
          <span style={{ color: color }} className="capitalize">{`${symbol} `}</span>
          <span className="text-gray-400">
            {`${payload[0].name} :  `}
            <span className="text-white">{`${payload[0].value}`}</span>
          </span>
          <span className="text-gray-400">
            {`${payload[1].name} :  `}
            <span className="text-white">{`${payload[1].value}`}%</span>
          </span>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex w-full flex-col lg:pl-5">
      <span className="inline-flex items-center space-x-1 text-3xl font-semibold">
        <span>Risk Metrics </span> <QuestionMarkCircleIcon className="mt-0.5 h-6 w-6" />
      </span>
      <div className="py-2">
        <ResponsiveContainer width="75%" height={440}>
          <ScatterChart
            margin={{
              // top: 13,
              right: 20,
              bottom: 20,
              left: 1,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#6b7280" />
            <XAxis
              tick={{ fontSize: 12, fill: '#ffffff' }}
              type="number"
              dataKey="monthlyReturnSd"
              name="Risk (SD)"
              label={{
                value: 'Risk (Standard Deviation)',
                position: 'bottom',
                fill: '#ffffff',
              }}
            />
            <YAxis
              label={{
                value: 'Returns',
                angle: -90,
                position: 'middle',
                fill: '#ffffff',
                dx: -25,
              }}
              tick={{ fontSize: 12, fill: '#ffffff' }}
              tickFormatter={(value) => `${value}%`}
              type="number"
              dataKey="monthlyReturnPercentage"
              name="Return"
            />
            <Tooltip content={<CustomTooltip />} cursor={{ stroke: '#6b7280', strokeWidth: 0.5 }} />
            <Legend
              verticalAlign="top"
              iconType="square"
              align="center"
              iconSize={12}
              height={44}
              formatter={renderColorfulLegendText}
            />
            {accountType === 'DEMO' && (
              <>
                <Scatter name={'Assets'} data={riskMetricsChart} fill="#6b7280" />
                <Scatter
                  key={2}
                  name={'Total'}
                  data={[
                    {
                      name: 'Total',
                      symbol: 'total',
                      monthlyReturnSd: 0.08,
                      monthlyReturnPercentage: 4,
                    },
                  ]}
                  fill="#FDE047"
                />
              </>
            )}
            {accountType === 'CRYPTO_FUND' && (
              <>
                <Scatter key={1} name={'Assets'} data={riskMetricsChart} fill="#6b7280" />
                <Scatter
                  key={2}
                  name={'Total'}
                  data={[
                    {
                      name: 'Total',
                      symbol: 'total',
                      monthlyReturnSd: 0.12,
                      monthlyReturnPercentage: 0.21,
                    },
                  ]}
                  fill="#FDE047"
                />
              </>
            )}
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default RiskMetricsChart;
