import React, { ReactElement, useContext, useEffect } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { StatBoxesState, setStatBoxesAsync } from '../../state-slices/statBoxesSlice';
import StateBox from './StatBox';
import { DashboardContext } from './Dashboard';

const StateBoxes = (): ReactElement => {
  const dispatch = useAppDispatch();
  const statBoxes: StatBoxesState = useSelector((state: RootState) => {
    return state.dashboard.statBoxes;
  });
  const { accountType } = useContext(DashboardContext);
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accountType]);

  const getData = async () => {
    try {
      await dispatch(setStatBoxesAsync(accountType)).unwrap();
    } catch (error) {
      console.error('There is an error', error);
    }
  };

  return (
    <>
      <div>
        <dl className="flex flex-row gap-5">
          {/* 1st box */}
          <StateBox
            text={'Net Asset Value'}
            statBoxValues={{
              value: statBoxes.data.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.netAssetValue ?? 0),
                0,
              ),
            }}
            status={statBoxes.status}
          />
          {/* 2nd box */}
          <StateBox
            text={'Total Return'}
            showValueInPrimaryColor={true}
            statBoxValues={{
              value: statBoxes.data.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.profitAndLoss ?? 0),
                0,
              ),
              percentage: (
                (statBoxes.data.reduce(
                  (accumulator, currentValue) => accumulator + (currentValue.profitAndLoss ?? 0),
                  0,
                ) /
                  statBoxes.data.reduce(
                    (accumulator, currentValue) => accumulator + (currentValue.equityAmount ?? 0),
                    0,
                  )) *
                100
              ).toFixed(2),
            }}
            status={statBoxes.status}
          />
          </dl>
          <dl className="flex flex-row gap-5">
          {/* 3rd box */}
          <StateBox
            text={'Equity Amount'}
            statBoxValues={{
              value: statBoxes.data.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.equityAmount ?? 0),
                0,
              ),
            }}
            className="col-start-1"
            status={statBoxes.status}
          />
          {/* 4th box */}
          <StateBox
            text={'Profit / Loss'}
            showValueInPrimaryColor={true}
            statBoxValues={{
              value: statBoxes.data.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.profitAndLoss ?? 0),
                0,
              ),
              percentage: (
                (statBoxes.data.reduce(
                  (accumulator, currentValue) => accumulator + (currentValue.profitAndLoss ?? 0),
                  0,
                ) /
                  statBoxes.data.reduce(
                    (accumulator, currentValue) => accumulator + (currentValue.equityAmount ?? 0),
                    0,
                  )) *
                100
              ).toFixed(2),
            }}
            status={statBoxes.status}
          />
        </dl>
      </div>
    </>
  );
};

export default StateBoxes;
