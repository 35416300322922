import React from 'react';
import AppLayout from './components/AppLayout';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { KeyIcon, HomeIcon } from '@heroicons/react/24/outline';
import './App.css';
import { RouteNames, RoutesList } from './components/context/contextTypes';
import { Suspense } from 'react';
import PageNotFound from './components/PageNotFound';
import Loader from './components/Loader';
import Dashboard from './components/dashboard/Dashboard';

export interface SidebarNavigation {
  name: RouteNames;
  icon: any;
  current: boolean;
  path: RoutesList;
}

function App() {
  const sideBarNavigationList: SidebarNavigation[] = [
    { name: 'Dashboard', icon: HomeIcon, current: false, path: 'dashboard' },
    { name: 'Scenarios', icon: KeyIcon, current: false, path: 'scenarios' },
  ];

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={
            <AppLayout sidebarNavigationList={sideBarNavigationList}>
              <Suspense fallback={<Loader height="h-screen" />}>
                <Outlet />
              </Suspense>
            </AppLayout>
          }
        >
          <Route index element={<Navigate to="dashboard" replace />}></Route>
          <Route path="dashboard" element={<Dashboard />}></Route>
          <Route path="scenarios" element={<h1 className="font-bold">Scenarios</h1>}></Route>
        </Route>
        <Route path="*" element={<PageNotFound />}>
          {' '}
        </Route>
      </Routes>
    </div>
  );
}

export default App;
