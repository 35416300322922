import { backendUrl } from './config/config';
import { AddressPortfoliosApi, Configuration, FundPortfoliosApi } from './ncis-api';

const configuration = new Configuration({
  apiKey: () => localStorage.getItem('apiKey') as string,
});

export const addressPortfoliosApi = new AddressPortfoliosApi(configuration, backendUrl);

export const fundPortfoliosApi = new FundPortfoliosApi(configuration, backendUrl);
