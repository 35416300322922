import React from 'react';

interface Props {
  error: any | null;
  className?: string;
}
const ShowErrorMessage: React.FC<Props> = ({ error, className = '' }) => {
  return (
    <div className={`flex items-center justify-center ${className}`}>
      <p className="text-2xl font-semibold text-red-500">
        {error?.message ? error.message : 'API failed to respond'}
      </p>
    </div>
  );
};

export default ShowErrorMessage;
