import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GaugesDto, GaugesResponseDto } from '../api/ncis-api/api';
import { addressPortfoliosApi, fundPortfoliosApi } from '../api/api';
import { AxiosResponse } from 'axios';
import { accountTypes, AccountTypes } from '../utils/types';

export type Status = 'idle' | 'loading' | 'failed';

export interface StatBoxesState {
  status: Status;
  error: any | null;
  data: Array<GaugesDto>;
}

const initialState: StatBoxesState = {
  data: [
    {
      timestamp: '2024-02-20T08:59:21.902Z',
      netAssetValue: 0,
      totalReturns: 0,
      equityAmount: 0,
      profitAndLoss: 0,
    },
  ],
  status: 'idle',
  error: null,
};

export function fetchStatBoxes(accountType: AccountTypes[keyof AccountTypes]) {
  return new Promise<GaugesDto[]>(async (resolve, reject) => {
    try {
      // const responseData = await new Promise<GaugesDto[]>((innerResolve) => {
      //   setTimeout(() => {
      //     innerResolve(gaugesDto);
      //   }, 500);
      // });
      const responseData = [];
      if (accountType === 'DEMO') {
        let response: AxiosResponse<GaugesResponseDto, any> =
          await addressPortfoliosApi.getAddressGauges(
            'BITCOIN',
            '36GEcFJPXdpLmqPEHMuJHaDi8mWxAuDopr',
          );
        responseData.push(response.data.data);
        response = await addressPortfoliosApi.getAddressGauges(
          'ETHEREUM',
          '0xb1f046d83d9e4a9e844b703795b9ec6c93917a70',
        );
        responseData.push(response.data.data);

        const filteredArray = responseData.filter((item): item is GaugesDto => !!item);
        resolve(filteredArray);
      } else {
        let response: AxiosResponse<GaugesResponseDto, any> = await fundPortfoliosApi.getFundGauges(
          accountTypes['DigitalX Crypto Fund'],
        );
        responseData.push(response.data.data);
        const filteredArray = responseData.filter((item): item is GaugesDto => !!item);
        resolve(filteredArray);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export const setStatBoxesAsync = createAsyncThunk(
  'dashboard/stat-boxes',
  async (accountType: AccountTypes[keyof AccountTypes]) => {
    const response = await fetchStatBoxes(accountType);
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const statBoxesSlice = createSlice({
  name: 'stat-boxes',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setStatBoxesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(setStatBoxesAsync.fulfilled, (state, action: PayloadAction<GaugesDto[]>) => {
        state.status = 'idle';
        if (action.payload !== undefined) {
          state.data = action.payload;
        }
      })
      .addCase(setStatBoxesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

// export const {} = statBoxesSlice.actions;

export default statBoxesSlice.reducer;
