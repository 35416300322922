import { ReactElement, useContext, useEffect, useState } from 'react';
import {
  AssetToSymbol,
  Opacity,
  PerformanceChartState,
  assetToSymbol,
  setPerformanceChartAsync,
  setTimePeriodState,
} from '../../state-slices/performanceChartSlice';
import { useAppDispatch } from '../../app/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { PerformanceChartDto, TimeSpanType } from '../../api/ncis-api';
import { AssetColorCode, assetColorCode } from '../../state-slices/allocationChartSlice';
import { getKeyByValue } from '../../utils/helpers';
import dayjs from 'dayjs';
import { DashboardContext } from './Dashboard';
import PerformanceChartTimePeriodSelector from './PerformanceChartTimePeriodSelector';

let tooltipActiveDots: string[] = [];

const PerformanceChart = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { accountType } = useContext(DashboardContext);
  const [performanceChart, setPerformanceChart] = useState<any[]>([]);

  const performanceChartData: PerformanceChartState = useSelector((state: RootState) => {
    return state.dashboard.performanceChart;
  });
  const [currentTimeSpanType, setCurrentTimeSpanType] = useState<TimeSpanType>('1y');

  const opacity: Opacity = Object.keys(assetToSymbol).reduce((acc: any, key: string) => {
    acc[key] = 1;
    return acc;
  }, {});

  const defaultOpacity = {
    opacity: opacity,
  };

  const [onClickAssetOpacity, setOnClickAssetOpacity] = useState<{ opacity: Opacity }>(
    defaultOpacity,
  );
  const [yAxisRange, setYAxisRange] = useState<{ min: number; max: number }>({
    min: -35,
    max: 1000,
  });
  const [currentCoordinates, setCurrentCoordinates] = useState<[number, number]>([0, 0]);
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accountType, currentTimeSpanType]);

  useEffect(() => {
    setMinMaxPerformancePercentage(performanceChart);
  }, [performanceChart]);

  const getData = async () => {
    dispatch(setPerformanceChartAsync({ accountType, timePeriod: currentTimeSpanType }));
  };

  useEffect(() => {
    switch (accountType) {
      case 'DEMO':
        makeResultForDemoAccount();
        break;
      case 'CRYPTO_FUND':
        makeResultForCryptoFund();
        break;
      default:
        makeResultForCryptoFund();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [performanceChartData]);

  // Function to find min and max performancePercentage
  function setMinMaxPerformancePercentage(data: any[]) {
    const allAssetsValuesWithAppliedAbs = data.flatMap((assetsAtDatePoint) => {
      return Object.values(assetsAtDatePoint)
        .filter((assetObject) => typeof assetObject === 'object')
        .map((assetObject: any) => Math.abs(assetObject.performancePercentage));
    });

    let absMax: number = Math.max(...allAssetsValuesWithAppliedAbs);
    absMax = Math.ceil(absMax / 10) * 10;
    setYAxisRange({ min: -absMax || 0, max: absMax || 0 });
  }

  const handleMouseClick = (obj: any) => {
    const { color } = obj;
    const assetSymbol: keyof AssetColorCode = getKeyByValue(
      assetColorCode,
      color,
    ) as keyof AssetColorCode;
    const assetName: any = getKeyByValue(assetToSymbol, assetSymbol) as any;
    let newOpacity: any = {
      opacity: {
        ...onClickAssetOpacity.opacity,
      },
    };

    if (onClickAssetOpacity.opacity[assetName] === 1) {
      newOpacity.opacity[assetName] = 0.4;

      if (Object.values(newOpacity.opacity).every((val) => val !== 1)) {
        return;
      }

      setOnClickAssetOpacity(newOpacity);
      return;
    }

    newOpacity.opacity[assetName] = 1;
    setOnClickAssetOpacity(newOpacity);
    return;
  };

  const filterValuesToRemoveBadValues = (result: any) => {
    return result.filter((performancePoint: any) => {
      const shouldFilterThePoint = Object.keys(assetToSymbol).map((assetName) => {
        if (Math.abs(performancePoint[assetName]?.performancePercentage || 0) > 150) {
          return true;
        } else {
          return false;
        }
      });

      return shouldFilterThePoint.some((e) => e === true) ? false : true;
    });
  };

  const makeResultForDemoAccount = () => {
    const newPerformanceChartData: PerformanceChartDto[][] = performanceChartData.data;

    const result = newPerformanceChartData[0].map((bitcoinPerformancePoint, index: number) => {
      let obj: any = {};

      newPerformanceChartData.forEach((assetPerformanceArray) => {
        obj.timestamp = assetPerformanceArray[index].timestamp;
        const assetPerformances = assetPerformanceArray[index]?.assetPerformances;
        if (assetPerformances?.length) {
          obj[assetPerformances[0].name as string] = assetPerformances[0];
        }
      });

      return obj;
    });

    const newResult = filterValuesToRemoveBadValues(result);
    setPerformanceChart(newResult);
  };

  const makeResultForCryptoFund = () => {
    const selectedAssets = Object.values(assetToSymbol);
    const newPerformanceChartData: PerformanceChartDto[][] = performanceChartData.data;

    const result = newPerformanceChartData[0].map((PerformancePoint, index: number) => {
      let obj: any = {};

      obj.timestamp = PerformancePoint.timestamp;
      const assetPerformances = PerformancePoint?.assetPerformances;
      if (assetPerformances?.length) {
        selectedAssets.forEach((assetSymbol) => {
          const selectedAssetPerformance = assetPerformances.find(
            (asset) => asset.symbol === assetSymbol,
          );
          if (selectedAssetPerformance !== undefined)
            obj[selectedAssetPerformance.name as string] = selectedAssetPerformance;
        });
      }

      return obj;
    });

    const newResult = filterValuesToRemoveBadValues(result);
    setPerformanceChart(newResult);
  };

  const renderColorfulLegendText = (value: string, entry: any) => {
    const color = entry.color as AssetColorCode[keyof AssetColorCode];
    const assetSymbol: keyof AssetColorCode = getKeyByValue(
      assetColorCode,
      color,
    ) as keyof AssetColorCode;

    const assetName: keyof AssetToSymbol = getKeyByValue(
      assetToSymbol,
      assetSymbol,
    ) as keyof AssetToSymbol;

    return (
      <span
        className={`${onClickAssetOpacity.opacity[assetName] !== 1 ? 'line-through' : ''} cursor-pointer px-1 py-0 text-sm hover:opacity-90`}
      >
        {assetSymbol}
      </span>
    );
  };

  // function to calculate the y coordinate of a given point in the y axis scale
  function getYCoordinate(
    value: number,
    yAxisRange: [number, number],
    coordinatesRange: [number, number],
  ): number {
    const [yAxisMin, yAxisMax] = yAxisRange;
    const [coordinatesMin, coordinatesMax] = coordinatesRange;

    // Calculate the proportional value
    const proportion = (value - yAxisMin) / (yAxisMax - yAxisMin);

    // Map the value to the coordinates range
    const result = proportion * (coordinatesMax - coordinatesMin) + coordinatesMin;

    return result;
  }

  const getAssetNameByColor = (color: AssetColorCode[keyof AssetColorCode]): string => {
    const assetSymbol: keyof AssetColorCode = getKeyByValue(
      assetColorCode,
      color,
    ) as keyof AssetColorCode;
    const assetName: any = getKeyByValue(assetToSymbol, assetSymbol) as any;
    return assetName;
  };

  const CustomTooltip = ({ active, payload, label, viewBox }: any) => {
    if (active && payload && payload.length) {
      let filteredPayload: any = [];
      let min: number = Infinity;
      payload.forEach((dataPoint: any, index: number): void => {
        const assetName = getAssetNameByColor(
          dataPoint.stroke as AssetColorCode[keyof AssetColorCode],
        );

        // Only process the assets whose opacity is 1 (visible)
        if (onClickAssetOpacity.opacity[assetName] === 1) {
          const yCoordinateValue = getYCoordinate(
            dataPoint.value,
            [yAxisRange.min, yAxisRange.max],
            [viewBox.height + viewBox.top, viewBox.top],
          );

          // return yCoordinateValue;
          const value = Math.abs(currentCoordinates[1] - yCoordinateValue);

          if (min > value) {
            min = value;

            filteredPayload = [];
            filteredPayload.push(payload[index]);
          } else if (min === value) {
            filteredPayload.push(payload[index]);
          }
        }
      });

      const activateThisAssetDots = filteredPayload.map((dataPoint: any) =>
        getAssetNameByColor(dataPoint.stroke as AssetColorCode[keyof AssetColorCode]),
      );

      tooltipActiveDots = activateThisAssetDots;

      return (
        <div className="text-sx grid grid-cols-1 justify-items-start rounded-lg border border-gray-500 bg-gray-800 p-3 opacity-90">
          <span style={{ color: filteredPayload[0].payload.fill }} className="italic text-gray-200">
            {dayjs(label).format('MMM D, YYYY')}
          </span>
          {filteredPayload.map((dataPoint: any) => {
            const color = dataPoint.stroke as AssetColorCode[keyof AssetColorCode];
            const assetSymbol: keyof AssetColorCode = getKeyByValue(
              assetColorCode,
              color,
            ) as keyof AssetColorCode;
            const assetName: any = getAssetNameByColor(
              dataPoint.stroke as AssetColorCode[keyof AssetColorCode],
            );

            return (
              <div key={color} className="grid grid-cols-1 justify-items-start gap-1">
                <span style={{ color: color }} className=" capitalize">
                  {assetSymbol}
                </span>
                <span className="text-gray-400">
                  {`Price :  `}
                  <span className="text-white">
                    ${dataPoint.payload[assetName].performancePrice?.toLocaleString()}
                  </span>
                </span>

                <span className="text-gray-400">
                  {`Performance (USD):  `}
                  <span className="text-white">
                    ${dataPoint.payload[assetName].performanceUsd?.toLocaleString()}
                  </span>
                </span>

                <span className="text-gray-400">
                  {`Performance (AUD):  `}
                  <span className="text-white">
                    ${dataPoint.payload[assetName].performancePrice?.toLocaleString()}
                  </span>
                </span>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  const formatXAxisTick = (tickItem: any) => {
    const tickedMonth: string = dayjs(tickItem).format('MMM');
    if (tickedMonth === 'Jan' || tickedMonth === 'Dec') {
      return dayjs(tickItem).format('MMM, YYYY');
    }

    return dayjs(tickItem).format('MMM');
  };
  const addFirstAndLastTimeStamp = (monthsList: string[], timeStampForAxis: string[]) => {
    const firstTickMonth = dayjs(performanceChart[0].timestamp).format('MMM');
    if (monthsList.includes(firstTickMonth) === false) {
      timeStampForAxis.unshift(performanceChart[0].timestamp);
    }
    const lastTickMonth = dayjs(performanceChart[performanceChart.length - 1].timestamp).format(
      'MMM',
    );
    if (monthsList.includes(lastTickMonth) === false) {
      timeStampForAxis.push(performanceChart[performanceChart.length - 1].timestamp);
    }

    return timeStampForAxis;
  };

  const createXAxisDataPoints = (): string[] => {
    const timeStampForAxis: string[] = [];
    performanceChart.reduce((acc, current) => {
      const currentMonth = dayjs(current.timestamp).format('MM/YYYY');
      if (acc.includes(currentMonth)) {
        return acc;
      }

      if (currentTimeSpanType === '5y') {
        if (['Jan', 'Jul'].includes(dayjs(current.timestamp).format('MMM')) === false) {
          return acc;
        }
      }

      if (currentTimeSpanType === '3y') {
        if (['Jan', 'May', 'Aug'].includes(dayjs(current.timestamp).format('MMM')) === false) {
          return acc;
        }
      }
      acc.push(currentMonth);
      timeStampForAxis.push(current.timestamp);
      return acc;
    }, []);

    if (performanceChart.length > 3) {
      if (currentTimeSpanType === '3y') {
        return addFirstAndLastTimeStamp(['Jan', 'May', 'Aug'], timeStampForAxis);
      }

      if (currentTimeSpanType === '5y') {
        return addFirstAndLastTimeStamp(['Jan', 'Jul'], timeStampForAxis);
      }
    }
    return timeStampForAxis;
  };

  const onLineChartMouseMove = (event: any) => {
    setCurrentCoordinates([event.activeCoordinate?.x, event.activeCoordinate?.y]);
  };

  return (
    <div className="flex flex-col lg:pr-5">
      <span className="inline-flex items-center space-x-1 text-3xl font-semibold">
        <span>Performance Metrics </span> <QuestionMarkCircleIcon className="mt-0.5 h-6 w-6" />
      </span>
      <div className="py-2">
        <div className="h-[420px] w-full">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={performanceChart} onMouseMove={onLineChartMouseMove}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} stroke="#6b7280" />
              <XAxis
                dataKey="timestamp"
                tick={{ fontSize: 12, fill: '#ffffff' }}
                ticks={createXAxisDataPoints()}
                tickFormatter={formatXAxisTick}
                padding="no-gap"
              ></XAxis>
              <YAxis
                type="number"
                tick={{ fontSize: 12, fill: '#ffffff' }}
                label={{
                  value: 'Price change',
                  angle: -90,
                  position: 'insideLeft',
                  fill: '#ffffff',
                }}
                tickFormatter={(value) => `${value}%`}
                domain={[yAxisRange.min, yAxisRange.max]}
              ></YAxis>
              <Tooltip
                content={<CustomTooltip />}
                cursor={{ stroke: '#6b7280', strokeWidth: 0.5 }}
              />
              <Legend
                // align="left"
                verticalAlign="top"
                iconType="square"
                iconSize={12}
                height={44}
                formatter={renderColorfulLegendText}
                onClick={handleMouseClick}
              />
              {accountType === 'DEMO' &&
                Object.entries({ Bitcoin: 'BTC', Ethereum: 'ETH' }).map(
                  ([asset, symbol], index) => {
                    return (
                      <Line
                        key={index}
                        dataKey={`${asset}.performancePercentage`}
                        // @ts-ignore
                        stroke={assetColorCode[symbol]}
                        activeDot={tooltipActiveDots.includes(asset) ? { r: 1 } : false}
                        strokeOpacity={onClickAssetOpacity.opacity[asset]}
                        dot={
                          onClickAssetOpacity.opacity[asset] === 1
                            ? { r: 1, clipDot: false }
                            : false
                        }
                      />
                    );
                  },
                )}

              {accountType === 'CRYPTO_FUND' &&
                Object.entries(assetToSymbol).map(([asset, symbol], index) => {
                  return (
                    <Line
                      key={index}
                      dataKey={`${asset}.performancePercentage`}
                      stroke={assetColorCode[symbol]}
                      activeDot={tooltipActiveDots.includes(asset) ? { r: 1 } : false}
                      strokeOpacity={onClickAssetOpacity.opacity[asset]}
                      // dot={{ r: 1, clipDot: false }}
                      dot={
                        onClickAssetOpacity.opacity[asset] === 1 ? { r: 1, clipDot: false } : false
                      }
                    />
                  );
                })}
            </LineChart>
          </ResponsiveContainer>
        </div>
        <PerformanceChartTimePeriodSelector
          currentTimeSpan={currentTimeSpanType}
          updateTimeSpan={(value: TimeSpanType) => {
            setCurrentTimeSpanType(value);
            dispatch(setTimePeriodState(value));
          }}
        />
      </div>
    </div>
  );
};

export default PerformanceChart;
