import { Fragment, useContext, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { classNames } from '../../utils/helpers';
import { AccountTypes, accountTypes } from '../../utils/types';
import { DashboardContext } from './Dashboard';

export default function Dropdown() {
  const { updateAccountType } = useContext(DashboardContext);
  const [activeAccountTypeName, setActiveAccountTypeName] =
    useState<keyof AccountTypes>('DigitalX Crypto Fund');

  return (
    <Menu as="div" className="relative w-44 text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-200 px-3 py-2.5 text-sm font-normal text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          {activeAccountTypeName}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-gray-200 shadow-lg ring-1 ring-primary ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {/* need to loop these items later */}
            <Menu.Item>
              {
                <button
                  onClick={() => {
                    setActiveAccountTypeName('Demo Account');
                    updateAccountType(accountTypes['Demo Account']);
                  }}
                  className={classNames(
                    activeAccountTypeName === 'Demo Account'
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm hover:bg-gray-100 ',
                  )}
                >
                  Demo Account
                </button>
              }
            </Menu.Item>
            <Menu.Item>
              {
                <button
                  onClick={() => {
                    setActiveAccountTypeName('DigitalX Crypto Fund');
                    updateAccountType(accountTypes['DigitalX Crypto Fund']);
                  }}
                  className={classNames(
                    activeAccountTypeName === 'DigitalX Crypto Fund'
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm hover:bg-gray-100',
                  )}
                >
                  DigitalX Crypto Fund
                </button>
              }
            </Menu.Item>
            <Menu.Item>
              {
                <button
                  onClick={() => {
                    setActiveAccountTypeName('DxART Fund');
                    updateAccountType(accountTypes['DxART Fund']);
                  }}
                  className={classNames(
                    activeAccountTypeName === 'DxART Fund'
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm hover:bg-gray-100',
                  )}
                >
                  DxART Fund
                </button>
              }
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
