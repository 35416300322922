import React, { createContext, useState } from 'react';
import Header from '../Header';
import DashboardBody from './DashboardBody';
import { AccountTypes, DashboardContextType } from '../../utils/types';

export const DashboardContext = createContext<DashboardContextType>({
  accountType: 'CRYPTO_FUND',
  updateAccountType: (val) => {},
});

const Dashboard: React.FC = () => {
  const [accountType, setAccountType] = useState<AccountTypes[keyof AccountTypes]>('CRYPTO_FUND');

  return (
    <>
      <DashboardContext.Provider
        value={{
          accountType: accountType,
          updateAccountType: (val: AccountTypes[keyof AccountTypes]) => {
            setAccountType(val);
          },
        }}
      >
        <Header title="Dashboard"></Header>
        <DashboardBody />
      </DashboardContext.Provider>
    </>
  );
};

export default Dashboard;
