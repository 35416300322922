import PlusIcon from '@heroicons/react/24/outline/PlusIcon';
import { RouteNames } from './context/contextTypes';
import Dropdown from './dashboard/Dropdown';

interface HeaderProps {
  title: RouteNames;
  showButton?: boolean;
}

const Header: React.FC<HeaderProps> = ({ title, showButton = true }) => {
  const handleEnterApiKey = () => {
    const apiKey = prompt("Please enter your API key:");
    if (apiKey) {
      localStorage.setItem('apiKey', apiKey);
    }
  };
  return (
    <div className="flex w-full items-center justify-between pb-12">
      <p className="hidden text-4xl font-semibold lg:block">{title}</p>
      <div className="flex flex-row items-center space-x-10">
        <Dropdown />
        {showButton && (
          <>
            <button className="inline-flex cursor-pointer items-center gap-x-2 rounded-md bg-primary px-3.5 py-2.5 text-sm font-medium text-black shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Use My Data
            </button>
            <button 
            onClick={handleEnterApiKey}
            className="inline-flex cursor-pointer items-center gap-x-2 rounded-md bg-primary px-3.5 py-2.5 text-sm font-medium text-black shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
              <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
              Api Key (Dev)
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
