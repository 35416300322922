import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PerformanceChartDto,
  PerformanceChartResponseDto,
  TimeSpanType,
} from '../api/ncis-api/api';
import { addressPortfoliosApi, fundPortfoliosApi } from '../api/api';
import { AxiosResponse } from 'axios';
import { Status } from './statBoxesSlice';
import { accountTypes, AccountTypes } from '../utils/types';
import { AssetColorCode } from './allocationChartSlice';
import { RootState } from '../app/store';

export interface PerformanceChartState {
  status: Status;
  error: any | null;
  data: PerformanceChartDto[][];
  timeSpan: TimeSpanType;
}
export type AssetToSymbol = { [key: string]: keyof AssetColorCode };
export const assetToSymbol: AssetToSymbol = {
  Bitcoin: 'BTC',
  Ethereum: 'ETH',
  Solana: 'SOL',
  Polygon: 'MATIC',
  Avalanche: 'AVAX',
};
export type Opacity = { [key in keyof AssetToSymbol]: number };

const initialState: PerformanceChartState = {
  status: 'idle',
  error: null,
  data: [
    [
      {
        timestamp: '2023-03-06T00:00:00Z',
        assetPerformances: [
          {
            name: 'Bitcoin',
            symbol: 'BTC',
            performancePrice: 0,
            performancePercentage: 1,
          },
        ],
      },
    ],
  ],
  timeSpan: '1y',
};

export function fetchPerformanceChart(
  accountType: AccountTypes[keyof AccountTypes],
  timePeriod: TimeSpanType,
) {
  return new Promise<PerformanceChartDto[][]>(async (resolve, reject) => {
    try {
      // const responseData = await new Promise<PerformanceChartDto[][]>((innerResolve) => {
      //   setTimeout(() => {
      //     innerResolve(performanceChart);
      //   }, 500);
      // });

      const responseData: PerformanceChartDto[][] = [];
      if (accountType === 'DEMO') {
        let response: AxiosResponse<PerformanceChartResponseDto, any> =
          await addressPortfoliosApi.getAddressPerformanceChart(
            'BITCOIN',
            '36GEcFJPXdpLmqPEHMuJHaDi8mWxAuDopr',
            timePeriod,
          );
        responseData.push(response.data.data as PerformanceChartDto[]);
        response = await addressPortfoliosApi.getAddressPerformanceChart(
          'ETHEREUM',
          '0xb1f046d83d9e4a9e844b703795b9ec6c93917a70',
          timePeriod,
        );

        responseData.push(response.data.data as PerformanceChartDto[]);

        resolve(responseData);
      } else {
        let response: AxiosResponse<PerformanceChartResponseDto, any> =
          await fundPortfoliosApi.getFundPerformanceChart(
            accountTypes['DigitalX Crypto Fund'],
            timePeriod,
          );

        responseData.push(response.data.data as PerformanceChartDto[]);

        const filteredArray = responseData.filter((item): item is PerformanceChartDto[] => !!item);

        resolve(filteredArray);
      }
    } catch (error) {
      reject(error);
    }
  });
}

export const setPerformanceChartAsync = createAsyncThunk(
  'dashboard/performance-chart',
  async (request: { accountType: AccountTypes[keyof AccountTypes]; timePeriod: TimeSpanType }) => {
    const response = await fetchPerformanceChart(request.accountType, request.timePeriod);
    // The value we return becomes the `fulfilled` action payload
    return response;
  },
);

export const PerformanceChartSlice = createSlice({
  name: 'allocation-chart',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setTimePeriodState: (state: PerformanceChartState, action: PayloadAction<TimeSpanType>) => {
      state.timeSpan = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setPerformanceChartAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(
        setPerformanceChartAsync.fulfilled,
        (state, action: PayloadAction<PerformanceChartDto[][]>) => {
          state.status = 'idle';
          if (action.payload !== undefined) {
            state.data = action.payload;
          }
        },
      )
      .addCase(setPerformanceChartAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export const { setTimePeriodState } = PerformanceChartSlice.actions;

// selector
export const selectTimeSpan = (state: RootState) => state.dashboard.performanceChart.timeSpan;

export default PerformanceChartSlice.reducer;
