import { ReactElement } from 'react';
import { classNames } from '../../utils/helpers';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { Status } from '../../state-slices/statBoxesSlice';
import Loader from '../Loader';
import ShowErrorMessage from '../ShowErrorMessage';

type StatBoxValues = {
  value: number | undefined;
  percentage?: number | string;
  benchmarkValue?: number;
  benchmarkPercentage?: number | string;
};
type StatBox = {
  text: string;
  showBenchMark?: Boolean;
  statBoxValues: StatBoxValues;
  showValueInPrimaryColor?: Boolean;
  className?: string;
  status: Status;
};
const StateBox = ({
  text,
  showValueInPrimaryColor = false,
  showBenchMark = false,
  statBoxValues,
  className,
  status,
}: StatBox): ReactElement => {
  return (
    <>
      {' '}
      <div
        className={`${className} col-span-4 flex flex-col space-y-1 md:min-h-[134px] md:max-w-64`}
      >
        <span className="inline-flex items-center space-x-1">
          <span>{text} </span> <QuestionMarkCircleIcon className="h-4 w-4" />
        </span>
        <div className="flex-1 overflow-hidden rounded-lg border border-gray-500 bg-gray-800 px-4 py-5 shadow sm:p-6 md:min-w-56">
          {status === 'idle' && (
            <>
              <dt
                title={`${statBoxValues?.percentage}%`}
                className={classNames(
                  statBoxValues?.percentage ? '' : 'hidden',
                  'text-wrap text-left text-2xl font-normal',
                )}
              >
                {statBoxValues?.percentage}%
              </dt>
              <dd
                title={`$${statBoxValues.value?.toLocaleString()}`}
                className={classNames(
                  showValueInPrimaryColor ? 'text-base text-primary' : 'text-2xl',
                  'text-wrap text-left font-normal',
                )}
              >
                ${statBoxValues.value?.toLocaleString()}
              </dd>
            </>
          )}
          {status === 'failed' && <ShowErrorMessage error={{ message: '-' }} />}
          {status === 'loading' && (
            <div className="">
              <Loader circleDiameter="h-1 w-1" adjustAlinement={false} height="md:min-h-2" />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default StateBox;
