/* tslint:disable */
/* eslint-disable */
/**
 * NCIS Api Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AddressType = {
    Bitcoin: 'BITCOIN',
    Ethereum: 'ETHEREUM'
} as const;

export type AddressType = typeof AddressType[keyof typeof AddressType];


/**
 * 
 * @export
 * @interface AllocationChartDto
 */
export interface AllocationChartDto {
    /**
     * 
     * @type {number}
     * @memberof AllocationChartDto
     */
    'totalAssetsBalanceAud'?: number;
    /**
     * 
     * @type {number}
     * @memberof AllocationChartDto
     */
    'totalAssetsBalanceUsd'?: number;
    /**
     * 
     * @type {Array<TokenBalanceDto>}
     * @memberof AllocationChartDto
     */
    'assetBalances'?: Array<TokenBalanceDto>;
}
/**
 * 
 * @export
 * @interface AllocationChartResponseDto
 */
export interface AllocationChartResponseDto {
    /**
     * 
     * @type {AllocationChartDto}
     * @memberof AllocationChartResponseDto
     */
    'data'?: AllocationChartDto;
}
/**
 * 
 * @export
 * @interface CustomScenarioDto
 */
export interface CustomScenarioDto {
    /**
     * 
     * @type {Array<TokenAllocationDto>}
     * @memberof CustomScenarioDto
     */
    'tokenAllocations'?: Array<TokenAllocationDto>;
}
/**
 * 
 * @export
 * @interface FundMetricDto
 */
export interface FundMetricDto {
    /**
     * 
     * @type {string}
     * @memberof FundMetricDto
     */
    'category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FundMetricDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FundMetricDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof FundMetricDto
     */
    'amountRewards'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FundMetricDto
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FundOperationTypeDto = {
    ManagementFee: 'MANAGEMENT_FEE',
    PerformanceFee: 'PERFORMANCE_FEE',
    InvestorWithdraw: 'INVESTOR_WITHDRAW',
    StakingReward: 'STAKING_REWARD',
    LendingInterestsReward: 'LENDING_INTERESTS_REWARD'
} as const;

export type FundOperationTypeDto = typeof FundOperationTypeDto[keyof typeof FundOperationTypeDto];


/**
 * 
 * @export
 * @enum {string}
 */

export const FundType = {
    BitcoinEtf: 'BITCOIN_ETF',
    CryptoFund: 'CRYPTO_FUND',
    RwatFund: 'RWAT_FUND'
} as const;

export type FundType = typeof FundType[keyof typeof FundType];


/**
 * 
 * @export
 * @interface GaugesDto
 */
export interface GaugesDto {
    /**
     * 
     * @type {string}
     * @memberof GaugesDto
     */
    'timestamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof GaugesDto
     */
    'netAssetValue'?: number;
    /**
     * 
     * @type {number}
     * @memberof GaugesDto
     */
    'totalReturns'?: number;
    /**
     * 
     * @type {number}
     * @memberof GaugesDto
     */
    'equityAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GaugesDto
     */
    'profitAndLoss'?: number;
}
/**
 * 
 * @export
 * @interface GaugesResponseDto
 */
export interface GaugesResponseDto {
    /**
     * 
     * @type {GaugesDto}
     * @memberof GaugesResponseDto
     */
    'data'?: GaugesDto;
}
/**
 * 
 * @export
 * @interface PerformanceChartDto
 */
export interface PerformanceChartDto {
    /**
     * 
     * @type {string}
     * @memberof PerformanceChartDto
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Array<TokenPerformanceDto>}
     * @memberof PerformanceChartDto
     */
    'assetPerformances'?: Array<TokenPerformanceDto>;
}
/**
 * 
 * @export
 * @interface PerformanceChartResponseDto
 */
export interface PerformanceChartResponseDto {
    /**
     * 
     * @type {Array<PerformanceChartDto>}
     * @memberof PerformanceChartResponseDto
     */
    'data'?: Array<PerformanceChartDto>;
}
/**
 * 
 * @export
 * @interface RiskMetricsChartDto
 */
export interface RiskMetricsChartDto {
    /**
     * 
     * @type {string}
     * @memberof RiskMetricsChartDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RiskMetricsChartDto
     */
    'symbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof RiskMetricsChartDto
     */
    'monthlyReturnSd'?: number;
    /**
     * 
     * @type {number}
     * @memberof RiskMetricsChartDto
     */
    'monthlyReturnPercentage'?: number;
}
/**
 * 
 * @export
 * @interface RiskMetricsChartResponseDto
 */
export interface RiskMetricsChartResponseDto {
    /**
     * 
     * @type {Array<RiskMetricsChartDto>}
     * @memberof RiskMetricsChartResponseDto
     */
    'data'?: Array<RiskMetricsChartDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ScenarioType = {
    EqualWeighted: 'EQUAL_WEIGHTED',
    RiskAdjusted: 'RISK_ADJUSTED',
    Custom: 'CUSTOM'
} as const;

export type ScenarioType = typeof ScenarioType[keyof typeof ScenarioType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TimeSpanType = {
    _3m: '3m',
    _6m: '6m',
    _1y: '1y',
    _3y: '3y',
    _5y: '5y'
} as const;

export type TimeSpanType = typeof TimeSpanType[keyof typeof TimeSpanType];


/**
 * 
 * @export
 * @interface TokenAllocationDto
 */
export interface TokenAllocationDto {
    /**
     * 
     * @type {string}
     * @memberof TokenAllocationDto
     */
    'symbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof TokenAllocationDto
     */
    'allocation'?: number;
}
/**
 * 
 * @export
 * @interface TokenBalanceDto
 */
export interface TokenBalanceDto {
    /**
     * 
     * @type {string}
     * @memberof TokenBalanceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenBalanceDto
     */
    'symbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof TokenBalanceDto
     */
    'amount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenBalanceDto
     */
    'stakedAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenBalanceDto
     */
    'rewardsAmount'?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenBalanceDto
     */
    'amountAud'?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenBalanceDto
     */
    'amountUsd'?: number;
}
/**
 * 
 * @export
 * @interface TokenPerformanceDto
 */
export interface TokenPerformanceDto {
    /**
     * 
     * @type {string}
     * @memberof TokenPerformanceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenPerformanceDto
     */
    'symbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof TokenPerformanceDto
     */
    'performancePercentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenPerformanceDto
     */
    'performancePrice'?: number;
}
/**
 * 
 * @export
 * @interface TokenPriceDto
 */
export interface TokenPriceDto {
    /**
     * 
     * @type {string}
     * @memberof TokenPriceDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenPriceDto
     */
    'symbol'?: string;
    /**
     * 
     * @type {number}
     * @memberof TokenPriceDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof TokenPriceDto
     */
    'timestamp'?: string;
}
/**
 * 
 * @export
 * @interface TokenPricePaginationResponseDto
 */
export interface TokenPricePaginationResponseDto {
    /**
     * 
     * @type {Array<TokenPriceDto>}
     * @memberof TokenPricePaginationResponseDto
     */
    'items'?: Array<TokenPriceDto>;
    /**
     * 
     * @type {number}
     * @memberof TokenPricePaginationResponseDto
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenPricePaginationResponseDto
     */
    'pageSize'?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenPricePaginationResponseDto
     */
    'pageCount'?: number;
}

/**
 * AddressPortfoliosApi - axios parameter creator
 * @export
 */
export const AddressPortfoliosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressAllocationChart: async (addressType: AddressType, address: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressType' is not null or undefined
            assertParamExists('getAddressAllocationChart', 'addressType', addressType)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('getAddressAllocationChart', 'address', address)
            const localVarPath = `/v1/address/{addressType}/{address}/portfolios/allocation-chart`
                .replace(`{${"addressType"}}`, encodeURIComponent(String(addressType)))
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressGauges: async (addressType: AddressType, address: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressType' is not null or undefined
            assertParamExists('getAddressGauges', 'addressType', addressType)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('getAddressGauges', 'address', address)
            const localVarPath = `/v1/address/{addressType}/{address}/portfolios/gauges`
                .replace(`{${"addressType"}}`, encodeURIComponent(String(addressType)))
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressPerformanceChart: async (addressType: AddressType, address: string, timeSpan: TimeSpanType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressType' is not null or undefined
            assertParamExists('getAddressPerformanceChart', 'addressType', addressType)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('getAddressPerformanceChart', 'address', address)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('getAddressPerformanceChart', 'timeSpan', timeSpan)
            const localVarPath = `/v1/address/{addressType}/{address}/portfolios/performance-chart`
                .replace(`{${"addressType"}}`, encodeURIComponent(String(addressType)))
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['timeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressRiskMetricsChart: async (addressType: AddressType, address: string, timeSpan: TimeSpanType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressType' is not null or undefined
            assertParamExists('getAddressRiskMetricsChart', 'addressType', addressType)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('getAddressRiskMetricsChart', 'address', address)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('getAddressRiskMetricsChart', 'timeSpan', timeSpan)
            const localVarPath = `/v1/address/{addressType}/{address}/portfolios/risk-metrics-chart`
                .replace(`{${"addressType"}}`, encodeURIComponent(String(addressType)))
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['timeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressPortfoliosApi - functional programming interface
 * @export
 */
export const AddressPortfoliosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressPortfoliosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressAllocationChart(addressType: AddressType, address: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressAllocationChart(addressType, address, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressPortfoliosApi.getAddressAllocationChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressGauges(addressType: AddressType, address: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GaugesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressGauges(addressType, address, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressPortfoliosApi.getAddressGauges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressPerformanceChart(addressType: AddressType, address: string, timeSpan: TimeSpanType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformanceChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressPerformanceChart(addressType, address, timeSpan, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressPortfoliosApi.getAddressPerformanceChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAddressRiskMetricsChart(addressType: AddressType, address: string, timeSpan: TimeSpanType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskMetricsChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAddressRiskMetricsChart(addressType, address, timeSpan, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AddressPortfoliosApi.getAddressRiskMetricsChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AddressPortfoliosApi - factory interface
 * @export
 */
export const AddressPortfoliosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressPortfoliosApiFp(configuration)
    return {
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressAllocationChart(addressType: AddressType, address: string, options?: any): AxiosPromise<AllocationChartResponseDto> {
            return localVarFp.getAddressAllocationChart(addressType, address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressGauges(addressType: AddressType, address: string, options?: any): AxiosPromise<GaugesResponseDto> {
            return localVarFp.getAddressGauges(addressType, address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressPerformanceChart(addressType: AddressType, address: string, timeSpan: TimeSpanType, options?: any): AxiosPromise<PerformanceChartResponseDto> {
            return localVarFp.getAddressPerformanceChart(addressType, address, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAddressRiskMetricsChart(addressType: AddressType, address: string, timeSpan: TimeSpanType, options?: any): AxiosPromise<RiskMetricsChartResponseDto> {
            return localVarFp.getAddressRiskMetricsChart(addressType, address, timeSpan, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressPortfoliosApi - object-oriented interface
 * @export
 * @class AddressPortfoliosApi
 * @extends {BaseAPI}
 */
export class AddressPortfoliosApi extends BaseAPI {
    /**
     * 
     * @param {AddressType} addressType 
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressPortfoliosApi
     */
    public getAddressAllocationChart(addressType: AddressType, address: string, options?: RawAxiosRequestConfig) {
        return AddressPortfoliosApiFp(this.configuration).getAddressAllocationChart(addressType, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddressType} addressType 
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressPortfoliosApi
     */
    public getAddressGauges(addressType: AddressType, address: string, options?: RawAxiosRequestConfig) {
        return AddressPortfoliosApiFp(this.configuration).getAddressGauges(addressType, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddressType} addressType 
     * @param {string} address 
     * @param {TimeSpanType} timeSpan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressPortfoliosApi
     */
    public getAddressPerformanceChart(addressType: AddressType, address: string, timeSpan: TimeSpanType, options?: RawAxiosRequestConfig) {
        return AddressPortfoliosApiFp(this.configuration).getAddressPerformanceChart(addressType, address, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddressType} addressType 
     * @param {string} address 
     * @param {TimeSpanType} timeSpan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressPortfoliosApi
     */
    public getAddressRiskMetricsChart(addressType: AddressType, address: string, timeSpan: TimeSpanType, options?: RawAxiosRequestConfig) {
        return AddressPortfoliosApiFp(this.configuration).getAddressRiskMetricsChart(addressType, address, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CronjobApi - axios parameter creator
 * @export
 */
export const CronjobApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nightlyUpdateFundMetricsCronjob: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cronjob/nightly-update-fund-metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nightlyUpdateFundTransactionsCronjob: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cronjob/nightly-update-fund-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nightlyUpdateTokenPricesCronjob: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cronjob/nightly-update-token-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nightlyUpdateUberCron: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cronjob/nightly-update-uber-cron`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CronjobApi - functional programming interface
 * @export
 */
export const CronjobApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CronjobApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nightlyUpdateFundMetricsCronjob(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nightlyUpdateFundMetricsCronjob(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CronjobApi.nightlyUpdateFundMetricsCronjob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nightlyUpdateFundTransactionsCronjob(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nightlyUpdateFundTransactionsCronjob(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CronjobApi.nightlyUpdateFundTransactionsCronjob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nightlyUpdateTokenPricesCronjob(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nightlyUpdateTokenPricesCronjob(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CronjobApi.nightlyUpdateTokenPricesCronjob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nightlyUpdateUberCron(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nightlyUpdateUberCron(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CronjobApi.nightlyUpdateUberCron']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CronjobApi - factory interface
 * @export
 */
export const CronjobApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CronjobApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nightlyUpdateFundMetricsCronjob(options?: any): AxiosPromise<void> {
            return localVarFp.nightlyUpdateFundMetricsCronjob(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nightlyUpdateFundTransactionsCronjob(options?: any): AxiosPromise<void> {
            return localVarFp.nightlyUpdateFundTransactionsCronjob(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nightlyUpdateTokenPricesCronjob(options?: any): AxiosPromise<void> {
            return localVarFp.nightlyUpdateTokenPricesCronjob(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nightlyUpdateUberCron(options?: any): AxiosPromise<void> {
            return localVarFp.nightlyUpdateUberCron(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CronjobApi - object-oriented interface
 * @export
 * @class CronjobApi
 * @extends {BaseAPI}
 */
export class CronjobApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronjobApi
     */
    public nightlyUpdateFundMetricsCronjob(options?: RawAxiosRequestConfig) {
        return CronjobApiFp(this.configuration).nightlyUpdateFundMetricsCronjob(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronjobApi
     */
    public nightlyUpdateFundTransactionsCronjob(options?: RawAxiosRequestConfig) {
        return CronjobApiFp(this.configuration).nightlyUpdateFundTransactionsCronjob(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronjobApi
     */
    public nightlyUpdateTokenPricesCronjob(options?: RawAxiosRequestConfig) {
        return CronjobApiFp(this.configuration).nightlyUpdateTokenPricesCronjob(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CronjobApi
     */
    public nightlyUpdateUberCron(options?: RawAxiosRequestConfig) {
        return CronjobApiFp(this.configuration).nightlyUpdateUberCron(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FundPortfoliosApi - axios parameter creator
 * @export
 */
export const FundPortfoliosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundAllocationChart: async (fundType: FundType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('getFundAllocationChart', 'fundType', fundType)
            const localVarPath = `/v1/funds/{fundType}/portfolios/allocation-chart`
                .replace(`{${"fundType"}}`, encodeURIComponent(String(fundType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundGauges: async (fundType: FundType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('getFundGauges', 'fundType', fundType)
            const localVarPath = `/v1/funds/{fundType}/portfolios/gauges`
                .replace(`{${"fundType"}}`, encodeURIComponent(String(fundType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundPerformanceChart: async (fundType: FundType, timeSpan: TimeSpanType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('getFundPerformanceChart', 'fundType', fundType)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('getFundPerformanceChart', 'timeSpan', timeSpan)
            const localVarPath = `/v1/funds/{fundType}/portfolios/performance-chart`
                .replace(`{${"fundType"}}`, encodeURIComponent(String(fundType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['timeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundRiskMetricsChart: async (fundType: FundType, timeSpan: TimeSpanType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('getFundRiskMetricsChart', 'fundType', fundType)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('getFundRiskMetricsChart', 'timeSpan', timeSpan)
            const localVarPath = `/v1/funds/{fundType}/portfolios/risk-metrics-chart`
                .replace(`{${"fundType"}}`, encodeURIComponent(String(fundType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['timeSpan'] = timeSpan;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [token] 
         * @param {string} [after] 
         * @param {string} [before] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundTokenPriceTable: async (fundType: FundType, page?: number, pageSize?: number, token?: string, after?: string, before?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('getFundTokenPriceTable', 'fundType', fundType)
            const localVarPath = `/v1/funds/{fundType}/portfolios/token-price-table`
                .replace(`{${"fundType"}}`, encodeURIComponent(String(fundType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = (after as any instanceof Date) ?
                    (after as any).toISOString() :
                    after;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = (before as any instanceof Date) ?
                    (before as any).toISOString() :
                    before;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FundPortfoliosApi - functional programming interface
 * @export
 */
export const FundPortfoliosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FundPortfoliosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundAllocationChart(fundType: FundType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundAllocationChart(fundType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundPortfoliosApi.getFundAllocationChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundGauges(fundType: FundType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GaugesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundGauges(fundType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundPortfoliosApi.getFundGauges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundPerformanceChart(fundType: FundType, timeSpan: TimeSpanType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformanceChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundPerformanceChart(fundType, timeSpan, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundPortfoliosApi.getFundPerformanceChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundRiskMetricsChart(fundType: FundType, timeSpan: TimeSpanType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RiskMetricsChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundRiskMetricsChart(fundType, timeSpan, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundPortfoliosApi.getFundRiskMetricsChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [token] 
         * @param {string} [after] 
         * @param {string} [before] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundTokenPriceTable(fundType: FundType, page?: number, pageSize?: number, token?: string, after?: string, before?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenPricePaginationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundTokenPriceTable(fundType, page, pageSize, token, after, before, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FundPortfoliosApi.getFundTokenPriceTable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FundPortfoliosApi - factory interface
 * @export
 */
export const FundPortfoliosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FundPortfoliosApiFp(configuration)
    return {
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundAllocationChart(fundType: FundType, options?: any): AxiosPromise<AllocationChartResponseDto> {
            return localVarFp.getFundAllocationChart(fundType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundGauges(fundType: FundType, options?: any): AxiosPromise<GaugesResponseDto> {
            return localVarFp.getFundGauges(fundType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundPerformanceChart(fundType: FundType, timeSpan: TimeSpanType, options?: any): AxiosPromise<PerformanceChartResponseDto> {
            return localVarFp.getFundPerformanceChart(fundType, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {TimeSpanType} timeSpan 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundRiskMetricsChart(fundType: FundType, timeSpan: TimeSpanType, options?: any): AxiosPromise<RiskMetricsChartResponseDto> {
            return localVarFp.getFundRiskMetricsChart(fundType, timeSpan, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [token] 
         * @param {string} [after] 
         * @param {string} [before] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundTokenPriceTable(fundType: FundType, page?: number, pageSize?: number, token?: string, after?: string, before?: string, options?: any): AxiosPromise<TokenPricePaginationResponseDto> {
            return localVarFp.getFundTokenPriceTable(fundType, page, pageSize, token, after, before, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FundPortfoliosApi - object-oriented interface
 * @export
 * @class FundPortfoliosApi
 * @extends {BaseAPI}
 */
export class FundPortfoliosApi extends BaseAPI {
    /**
     * 
     * @param {FundType} fundType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundPortfoliosApi
     */
    public getFundAllocationChart(fundType: FundType, options?: RawAxiosRequestConfig) {
        return FundPortfoliosApiFp(this.configuration).getFundAllocationChart(fundType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FundType} fundType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundPortfoliosApi
     */
    public getFundGauges(fundType: FundType, options?: RawAxiosRequestConfig) {
        return FundPortfoliosApiFp(this.configuration).getFundGauges(fundType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FundType} fundType 
     * @param {TimeSpanType} timeSpan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundPortfoliosApi
     */
    public getFundPerformanceChart(fundType: FundType, timeSpan: TimeSpanType, options?: RawAxiosRequestConfig) {
        return FundPortfoliosApiFp(this.configuration).getFundPerformanceChart(fundType, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FundType} fundType 
     * @param {TimeSpanType} timeSpan 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundPortfoliosApi
     */
    public getFundRiskMetricsChart(fundType: FundType, timeSpan: TimeSpanType, options?: RawAxiosRequestConfig) {
        return FundPortfoliosApiFp(this.configuration).getFundRiskMetricsChart(fundType, timeSpan, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FundType} fundType 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [token] 
     * @param {string} [after] 
     * @param {string} [before] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FundPortfoliosApi
     */
    public getFundTokenPriceTable(fundType: FundType, page?: number, pageSize?: number, token?: string, after?: string, before?: string, options?: RawAxiosRequestConfig) {
        return FundPortfoliosApiFp(this.configuration).getFundTokenPriceTable(fundType, page, pageSize, token, after, before, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InternalApi - axios parameter creator
 * @export
 */
export const InternalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressTransactions: async (addressType: AddressType, address: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressType' is not null or undefined
            assertParamExists('updateAddressTransactions', 'addressType', addressType)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('updateAddressTransactions', 'address', address)
            const localVarPath = `/internal/update-address-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (addressType !== undefined) {
                localVarQueryParameter['addressType'] = addressType;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDailyAddressMetrics: async (addressType: AddressType, address: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressType' is not null or undefined
            assertParamExists('updateDailyAddressMetrics', 'addressType', addressType)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('updateDailyAddressMetrics', 'address', address)
            const localVarPath = `/internal/update-daily-address-metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (addressType !== undefined) {
                localVarQueryParameter['addressType'] = addressType;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDailyFundMetrics: async (fundType: FundType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('updateDailyFundMetrics', 'fundType', fundType)
            const localVarPath = `/internal/update-daily-fund-metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (fundType !== undefined) {
                localVarQueryParameter['fundType'] = fundType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} timestamp 
         * @param {FundType} fundType 
         * @param {FundOperationTypeDto} operationType 
         * @param {number} amountUsd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFundOperation: async (timestamp: string, fundType: FundType, operationType: FundOperationTypeDto, amountUsd: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'timestamp' is not null or undefined
            assertParamExists('updateFundOperation', 'timestamp', timestamp)
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('updateFundOperation', 'fundType', fundType)
            // verify required parameter 'operationType' is not null or undefined
            assertParamExists('updateFundOperation', 'operationType', operationType)
            // verify required parameter 'amountUsd' is not null or undefined
            assertParamExists('updateFundOperation', 'amountUsd', amountUsd)
            const localVarPath = `/internal/update-fund-operation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }

            if (fundType !== undefined) {
                localVarQueryParameter['fundType'] = fundType;
            }

            if (operationType !== undefined) {
                localVarQueryParameter['operationType'] = operationType;
            }

            if (amountUsd !== undefined) {
                localVarQueryParameter['amountUsd'] = amountUsd;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFundTransactions: async (fundType: FundType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('updateFundTransactions', 'fundType', fundType)
            const localVarPath = `/internal/update-fund-transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (fundType !== undefined) {
                localVarQueryParameter['fundType'] = fundType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} symbol 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTokenPrices: async (symbol: string, start: string, end: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'symbol' is not null or undefined
            assertParamExists('updateTokenPrices', 'symbol', symbol)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('updateTokenPrices', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('updateTokenPrices', 'end', end)
            const localVarPath = `/internal/update-token-prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (symbol !== undefined) {
                localVarQueryParameter['symbol'] = symbol;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InternalApi - functional programming interface
 * @export
 */
export const InternalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InternalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAddressTransactions(addressType: AddressType, address: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAddressTransactions(addressType, address, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.updateAddressTransactions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDailyAddressMetrics(addressType: AddressType, address: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDailyAddressMetrics(addressType, address, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.updateDailyAddressMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDailyFundMetrics(fundType: FundType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDailyFundMetrics(fundType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.updateDailyFundMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} timestamp 
         * @param {FundType} fundType 
         * @param {FundOperationTypeDto} operationType 
         * @param {number} amountUsd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFundOperation(timestamp: string, fundType: FundType, operationType: FundOperationTypeDto, amountUsd: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFundOperation(timestamp, fundType, operationType, amountUsd, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.updateFundOperation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFundTransactions(fundType: FundType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFundTransactions(fundType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.updateFundTransactions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} symbol 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTokenPrices(symbol: string, start: string, end: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTokenPrices(symbol, start, end, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InternalApi.updateTokenPrices']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InternalApi - factory interface
 * @export
 */
export const InternalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InternalApiFp(configuration)
    return {
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAddressTransactions(addressType: AddressType, address: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateAddressTransactions(addressType, address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDailyAddressMetrics(addressType: AddressType, address: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateDailyAddressMetrics(addressType, address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDailyFundMetrics(fundType: FundType, options?: any): AxiosPromise<void> {
            return localVarFp.updateDailyFundMetrics(fundType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} timestamp 
         * @param {FundType} fundType 
         * @param {FundOperationTypeDto} operationType 
         * @param {number} amountUsd 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFundOperation(timestamp: string, fundType: FundType, operationType: FundOperationTypeDto, amountUsd: number, options?: any): AxiosPromise<void> {
            return localVarFp.updateFundOperation(timestamp, fundType, operationType, amountUsd, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFundTransactions(fundType: FundType, options?: any): AxiosPromise<void> {
            return localVarFp.updateFundTransactions(fundType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} symbol 
         * @param {string} start 
         * @param {string} end 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTokenPrices(symbol: string, start: string, end: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateTokenPrices(symbol, start, end, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InternalApi - object-oriented interface
 * @export
 * @class InternalApi
 * @extends {BaseAPI}
 */
export class InternalApi extends BaseAPI {
    /**
     * 
     * @param {AddressType} addressType 
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateAddressTransactions(addressType: AddressType, address: string, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).updateAddressTransactions(addressType, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddressType} addressType 
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateDailyAddressMetrics(addressType: AddressType, address: string, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).updateDailyAddressMetrics(addressType, address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FundType} fundType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateDailyFundMetrics(fundType: FundType, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).updateDailyFundMetrics(fundType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} timestamp 
     * @param {FundType} fundType 
     * @param {FundOperationTypeDto} operationType 
     * @param {number} amountUsd 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateFundOperation(timestamp: string, fundType: FundType, operationType: FundOperationTypeDto, amountUsd: number, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).updateFundOperation(timestamp, fundType, operationType, amountUsd, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FundType} fundType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateFundTransactions(fundType: FundType, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).updateFundTransactions(fundType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} symbol 
     * @param {string} start 
     * @param {string} end 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InternalApi
     */
    public updateTokenPrices(symbol: string, start: string, end: string, options?: RawAxiosRequestConfig) {
        return InternalApiFp(this.configuration).updateTokenPrices(symbol, start, end, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NavApi - axios parameter creator
 * @export
 */
export const NavApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FundType} fundType 
         * @param {Array<FundMetricDto>} fundMetricDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFundMetrics: async (fundType: FundType, fundMetricDto: Array<FundMetricDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('createFundMetrics', 'fundType', fundType)
            // verify required parameter 'fundMetricDto' is not null or undefined
            assertParamExists('createFundMetrics', 'fundMetricDto', fundMetricDto)
            const localVarPath = `/v1/nav/{fundType}/metrics`
                .replace(`{${"fundType"}}`, encodeURIComponent(String(fundType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fundMetricDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundMetrics: async (fundType: FundType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('getFundMetrics', 'fundType', fundType)
            const localVarPath = `/v1/nav/{fundType}/metrics`
                .replace(`{${"fundType"}}`, encodeURIComponent(String(fundType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NavApi - functional programming interface
 * @export
 */
export const NavApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NavApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FundType} fundType 
         * @param {Array<FundMetricDto>} fundMetricDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFundMetrics(fundType: FundType, fundMetricDto: Array<FundMetricDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFundMetrics(fundType, fundMetricDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NavApi.createFundMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFundMetrics(fundType: FundType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFundMetrics(fundType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['NavApi.getFundMetrics']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * NavApi - factory interface
 * @export
 */
export const NavApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NavApiFp(configuration)
    return {
        /**
         * 
         * @param {FundType} fundType 
         * @param {Array<FundMetricDto>} fundMetricDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFundMetrics(fundType: FundType, fundMetricDto: Array<FundMetricDto>, options?: any): AxiosPromise<void> {
            return localVarFp.createFundMetrics(fundType, fundMetricDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFundMetrics(fundType: FundType, options?: any): AxiosPromise<AllocationChartResponseDto> {
            return localVarFp.getFundMetrics(fundType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NavApi - object-oriented interface
 * @export
 * @class NavApi
 * @extends {BaseAPI}
 */
export class NavApi extends BaseAPI {
    /**
     * 
     * @param {FundType} fundType 
     * @param {Array<FundMetricDto>} fundMetricDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavApi
     */
    public createFundMetrics(fundType: FundType, fundMetricDto: Array<FundMetricDto>, options?: RawAxiosRequestConfig) {
        return NavApiFp(this.configuration).createFundMetrics(fundType, fundMetricDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FundType} fundType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NavApi
     */
    public getFundMetrics(fundType: FundType, options?: RawAxiosRequestConfig) {
        return NavApiFp(this.configuration).getFundMetrics(fundType, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ScenariosApi - axios parameter creator
 * @export
 */
export const ScenariosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAddressAllocationChart: async (addressType: AddressType, address: string, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressType' is not null or undefined
            assertParamExists('calculateAddressAllocationChart', 'addressType', addressType)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('calculateAddressAllocationChart', 'address', address)
            // verify required parameter 'scenarioType' is not null or undefined
            assertParamExists('calculateAddressAllocationChart', 'scenarioType', scenarioType)
            const localVarPath = `/v1/address/{addressType}/{address}/portfolios/allocation-chart`
                .replace(`{${"addressType"}}`, encodeURIComponent(String(addressType)))
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (scenarioType !== undefined) {
                localVarQueryParameter['scenarioType'] = scenarioType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customScenarioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAddressGauges: async (addressType: AddressType, address: string, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressType' is not null or undefined
            assertParamExists('calculateAddressGauges', 'addressType', addressType)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('calculateAddressGauges', 'address', address)
            // verify required parameter 'scenarioType' is not null or undefined
            assertParamExists('calculateAddressGauges', 'scenarioType', scenarioType)
            const localVarPath = `/v1/address/{addressType}/{address}/portfolios/gauges`
                .replace(`{${"addressType"}}`, encodeURIComponent(String(addressType)))
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (scenarioType !== undefined) {
                localVarQueryParameter['scenarioType'] = scenarioType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customScenarioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {TimeSpanType} timeSpan 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAddressPerformanceChart: async (addressType: AddressType, address: string, timeSpan: TimeSpanType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressType' is not null or undefined
            assertParamExists('calculateAddressPerformanceChart', 'addressType', addressType)
            // verify required parameter 'address' is not null or undefined
            assertParamExists('calculateAddressPerformanceChart', 'address', address)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('calculateAddressPerformanceChart', 'timeSpan', timeSpan)
            // verify required parameter 'scenarioType' is not null or undefined
            assertParamExists('calculateAddressPerformanceChart', 'scenarioType', scenarioType)
            const localVarPath = `/v1/address/{addressType}/{address}/portfolios/performance-chart`
                .replace(`{${"addressType"}}`, encodeURIComponent(String(addressType)))
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['timeSpan'] = timeSpan;
            }

            if (scenarioType !== undefined) {
                localVarQueryParameter['scenarioType'] = scenarioType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customScenarioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFundAllocationChart: async (fundType: FundType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('calculateFundAllocationChart', 'fundType', fundType)
            // verify required parameter 'scenarioType' is not null or undefined
            assertParamExists('calculateFundAllocationChart', 'scenarioType', scenarioType)
            const localVarPath = `/v1/funds/{fundType}/portfolios/allocation-chart`
                .replace(`{${"fundType"}}`, encodeURIComponent(String(fundType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (scenarioType !== undefined) {
                localVarQueryParameter['scenarioType'] = scenarioType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customScenarioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFundGauges: async (fundType: FundType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('calculateFundGauges', 'fundType', fundType)
            // verify required parameter 'scenarioType' is not null or undefined
            assertParamExists('calculateFundGauges', 'scenarioType', scenarioType)
            const localVarPath = `/v1/funds/{fundType}/portfolios/gauges`
                .replace(`{${"fundType"}}`, encodeURIComponent(String(fundType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (scenarioType !== undefined) {
                localVarQueryParameter['scenarioType'] = scenarioType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customScenarioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {TimeSpanType} timeSpan 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFundPerformanceChart: async (fundType: FundType, timeSpan: TimeSpanType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fundType' is not null or undefined
            assertParamExists('calculateFundPerformanceChart', 'fundType', fundType)
            // verify required parameter 'timeSpan' is not null or undefined
            assertParamExists('calculateFundPerformanceChart', 'timeSpan', timeSpan)
            // verify required parameter 'scenarioType' is not null or undefined
            assertParamExists('calculateFundPerformanceChart', 'scenarioType', scenarioType)
            const localVarPath = `/v1/funds/{fundType}/portfolios/performance-chart`
                .replace(`{${"fundType"}}`, encodeURIComponent(String(fundType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-api-key", configuration)

            if (timeSpan !== undefined) {
                localVarQueryParameter['timeSpan'] = timeSpan;
            }

            if (scenarioType !== undefined) {
                localVarQueryParameter['scenarioType'] = scenarioType;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customScenarioDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScenariosApi - functional programming interface
 * @export
 */
export const ScenariosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScenariosApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateAddressAllocationChart(addressType: AddressType, address: string, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateAddressAllocationChart(addressType, address, scenarioType, customScenarioDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScenariosApi.calculateAddressAllocationChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateAddressGauges(addressType: AddressType, address: string, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GaugesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateAddressGauges(addressType, address, scenarioType, customScenarioDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScenariosApi.calculateAddressGauges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {TimeSpanType} timeSpan 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateAddressPerformanceChart(addressType: AddressType, address: string, timeSpan: TimeSpanType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformanceChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateAddressPerformanceChart(addressType, address, timeSpan, scenarioType, customScenarioDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScenariosApi.calculateAddressPerformanceChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateFundAllocationChart(fundType: FundType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AllocationChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateFundAllocationChart(fundType, scenarioType, customScenarioDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScenariosApi.calculateFundAllocationChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateFundGauges(fundType: FundType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GaugesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateFundGauges(fundType, scenarioType, customScenarioDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScenariosApi.calculateFundGauges']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {TimeSpanType} timeSpan 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateFundPerformanceChart(fundType: FundType, timeSpan: TimeSpanType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PerformanceChartResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateFundPerformanceChart(fundType, timeSpan, scenarioType, customScenarioDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ScenariosApi.calculateFundPerformanceChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ScenariosApi - factory interface
 * @export
 */
export const ScenariosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScenariosApiFp(configuration)
    return {
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAddressAllocationChart(addressType: AddressType, address: string, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: any): AxiosPromise<AllocationChartResponseDto> {
            return localVarFp.calculateAddressAllocationChart(addressType, address, scenarioType, customScenarioDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAddressGauges(addressType: AddressType, address: string, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: any): AxiosPromise<GaugesResponseDto> {
            return localVarFp.calculateAddressGauges(addressType, address, scenarioType, customScenarioDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddressType} addressType 
         * @param {string} address 
         * @param {TimeSpanType} timeSpan 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateAddressPerformanceChart(addressType: AddressType, address: string, timeSpan: TimeSpanType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: any): AxiosPromise<PerformanceChartResponseDto> {
            return localVarFp.calculateAddressPerformanceChart(addressType, address, timeSpan, scenarioType, customScenarioDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFundAllocationChart(fundType: FundType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: any): AxiosPromise<AllocationChartResponseDto> {
            return localVarFp.calculateFundAllocationChart(fundType, scenarioType, customScenarioDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFundGauges(fundType: FundType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: any): AxiosPromise<GaugesResponseDto> {
            return localVarFp.calculateFundGauges(fundType, scenarioType, customScenarioDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FundType} fundType 
         * @param {TimeSpanType} timeSpan 
         * @param {ScenarioType} scenarioType 
         * @param {CustomScenarioDto} [customScenarioDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateFundPerformanceChart(fundType: FundType, timeSpan: TimeSpanType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: any): AxiosPromise<PerformanceChartResponseDto> {
            return localVarFp.calculateFundPerformanceChart(fundType, timeSpan, scenarioType, customScenarioDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScenariosApi - object-oriented interface
 * @export
 * @class ScenariosApi
 * @extends {BaseAPI}
 */
export class ScenariosApi extends BaseAPI {
    /**
     * 
     * @param {AddressType} addressType 
     * @param {string} address 
     * @param {ScenarioType} scenarioType 
     * @param {CustomScenarioDto} [customScenarioDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public calculateAddressAllocationChart(addressType: AddressType, address: string, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).calculateAddressAllocationChart(addressType, address, scenarioType, customScenarioDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddressType} addressType 
     * @param {string} address 
     * @param {ScenarioType} scenarioType 
     * @param {CustomScenarioDto} [customScenarioDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public calculateAddressGauges(addressType: AddressType, address: string, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).calculateAddressGauges(addressType, address, scenarioType, customScenarioDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddressType} addressType 
     * @param {string} address 
     * @param {TimeSpanType} timeSpan 
     * @param {ScenarioType} scenarioType 
     * @param {CustomScenarioDto} [customScenarioDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public calculateAddressPerformanceChart(addressType: AddressType, address: string, timeSpan: TimeSpanType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).calculateAddressPerformanceChart(addressType, address, timeSpan, scenarioType, customScenarioDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FundType} fundType 
     * @param {ScenarioType} scenarioType 
     * @param {CustomScenarioDto} [customScenarioDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public calculateFundAllocationChart(fundType: FundType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).calculateFundAllocationChart(fundType, scenarioType, customScenarioDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FundType} fundType 
     * @param {ScenarioType} scenarioType 
     * @param {CustomScenarioDto} [customScenarioDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public calculateFundGauges(fundType: FundType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).calculateFundGauges(fundType, scenarioType, customScenarioDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FundType} fundType 
     * @param {TimeSpanType} timeSpan 
     * @param {ScenarioType} scenarioType 
     * @param {CustomScenarioDto} [customScenarioDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScenariosApi
     */
    public calculateFundPerformanceChart(fundType: FundType, timeSpan: TimeSpanType, scenarioType: ScenarioType, customScenarioDto?: CustomScenarioDto, options?: RawAxiosRequestConfig) {
        return ScenariosApiFp(this.configuration).calculateFundPerformanceChart(fundType, timeSpan, scenarioType, customScenarioDto, options).then((request) => request(this.axios, this.basePath));
    }
}



