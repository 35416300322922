export const accountTypes = {
  'Demo Account': 'DEMO',
  'DigitalX Crypto Fund': 'CRYPTO_FUND',
  'DxART Fund': 'DxART_FUND',
} as const;

export type AccountTypes = typeof accountTypes;

export type DashboardContextType = {
  accountType: AccountTypes[keyof AccountTypes];
  updateAccountType: (val: AccountTypes[keyof AccountTypes]) => void;
};
