import React, { ReactElement } from 'react';
import { TimeSpanType } from '../../api/ncis-api';
import { classNames } from '../../utils/helpers';

interface Props {
  updateTimeSpan: (value: TimeSpanType) => void;
  currentTimeSpan: TimeSpanType;
}
// Define a functional component
const PerformanceChartTimePeriodSelector = ({
  currentTimeSpan,
  updateTimeSpan,
}: Props): ReactElement => {
  const timeSpanTypes = Object.values(TimeSpanType);

  return (
    <div className="flex justify-end">
      <div className="flex flex-shrink flex-row gap-3 rounded-md border border-gray-500 bg-gray-800 px-1 py-1">
        {timeSpanTypes.map((timeSpanType: TimeSpanType, index: number) => (
          <button
            key={index}
            className={classNames(
              currentTimeSpan === timeSpanType ? 'bg-gray-500' : 'bg-gray-800',
              'rounded-md px-2 py-0.5 text-sm text-white',
            )}
            onClick={() => updateTimeSpan(timeSpanType)}
          >
            {timeSpanType}
          </button>
        ))}
      </div>
    </div>
  );
};

export default PerformanceChartTimePeriodSelector;
