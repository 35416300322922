import { ReactElement, useContext, useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { assetColorCode, setAllocationChartAsync } from '../../state-slices/allocationChartSlice';
import { AllocationChartDto, TokenBalanceDto } from '../../api/ncis-api';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import ShowErrorMessage from '../ShowErrorMessage';
import Loader from '../Loader';
import { DashboardContext } from './Dashboard';
const AllocationChart = (): ReactElement => {
  const dispatch = useAppDispatch();
  const [pieData, setPieData] = useState<TokenBalanceDto[]>([]);
  const { accountType } = useContext(DashboardContext);

  const allocationChartData = useSelector((state: RootState) => {
    return state.dashboard.allocationChart;
  });

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accountType]);

  const totalNetAssetValue = useSelector((state: RootState) => {
    return state.dashboard.statBoxes.data.reduce(
      (accumulator, currentValue) => accumulator + (currentValue.netAssetValue ?? 0),
      0,
    );
  });

  const makeResultForDemoAccount = () => {
    const newPieData: TokenBalanceDto[] = allocationChartData.data
      .flatMap(
        (allocationChartDataPoint: AllocationChartDto): TokenBalanceDto[] | undefined =>
          allocationChartDataPoint.assetBalances ?? [],
      )
      .filter((point: TokenBalanceDto | undefined): point is TokenBalanceDto => {
        if (point === undefined) {
          return false;
        }
        return true;
      });
    setPieData(newPieData);
  };

  const makeResultForCryptoFund = () => {
    let newPieData: TokenBalanceDto[] = allocationChartData.data[0].assetBalances ?? [];
    newPieData = [...newPieData];
    // Sort the data array based on amountUsd in descending order
    newPieData = newPieData.sort((a, b) => (b.amountUsd || 0) - (a.amountUsd || 0));

    // Select the top 5 assets
    const top5Assets = newPieData.slice(0, 5);

    // Calculate the sum of amountUsd, amountAud, and amount for the remaining assets
    const others = newPieData.slice(5).reduce(
      (acc, asset) => {
        if (
          acc.amountUsd !== undefined &&
          acc.amountAud !== undefined &&
          acc.amount !== undefined
        ) {
          acc.amountUsd += asset.amountUsd || 0;
          acc.amountAud += asset.amountAud || 0;
          acc.amount += asset.amount || 0;
        }
        return acc;
      },
      { name: 'Others', symbol: 'OTHER', amount: 0, amountAud: 0, amountUsd: 0 },
    );

    // Append 'Others' object to the end of the top5Assets array
    newPieData = [...top5Assets, others];

    setPieData(newPieData);
  };

  useEffect(() => {
    switch (accountType) {
      case 'DEMO':
        makeResultForDemoAccount();
        break;
      case 'CRYPTO_FUND':
        makeResultForCryptoFund();
        break;
      default:
        makeResultForCryptoFund();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocationChartData]);

  const getData = async () => {
    dispatch(setAllocationChartAsync(accountType));
  };

  const renderColorfulLegendText = (value: string, entry: any) => {
    const { color } = entry;
    return (
      <span style={{ color }} className="px-1 py-0 text-sm">
        {entry.payload.symbol}
      </span>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className=" text-sx grid grid-cols-1 justify-items-start gap-1 rounded-lg border border-gray-500 bg-gray-800 p-3 opacity-90">
          <span style={{ color: payload[0].payload.fill }} className="capitalize">
            {payload[0].name}
          </span>
          <span className="text-gray-400">
            {`Amount :  `}
            <span className="text-white">{`${payload[0].payload.amount} ${payload[0].payload.symbol}`}</span>
          </span>
          <span className="text-gray-400">
            {`Amount (USD) :  `}
            <span className="text-white">{`$${payload[0].payload.amountUsd?.toLocaleString()}`}</span>
          </span>
          <span className="text-gray-400">
            {`Amount (AUD) :  `}
            <span className="text-white">{` $${payload[0].payload.amountAud?.toLocaleString()}`}</span>
          </span>
        </div>
      );
    }

    return null;
  };

  if (allocationChartData.status === 'loading') {
    return (
      <div className="px-2">
        <Loader circleDiameter="h-2 w-2" height="md:min-h-[352px]" />
      </div>
    );
  }

  if (allocationChartData.status === 'failed') {
    return <ShowErrorMessage error={allocationChartData.error} className="md:min-h-[352px]" />;
  }

  return (
    <>
      <div className="flex flex-col">
        <span className="inline-flex items-center space-x-1 text-3xl font-semibold">
          <span>Allocation </span> <QuestionMarkCircleIcon className="mt-0.5 h-6 w-6" />
        </span>
        <div className="py-2">
          <PieChart width={400} height={300}>
            <Pie
              data={pieData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={130}
              innerRadius={65}
              dataKey="amountUsd"
            >
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  // @ts-ignore
                  fill={assetColorCode[entry?.symbol ?? '#fffff']}
                  stroke="none"
                />
              ))}
            </Pie>
            <Legend
              align="left"
              verticalAlign="middle"
              layout="vertical"
              name="symbol"
              iconType="square"
              iconSize={12}
              formatter={renderColorfulLegendText}
            />
            <Tooltip content={<CustomTooltip />} />
            <text
              className="text-base"
              x="58%"
              y="50%"
              textAnchor="middle"
              dominantBaseline="middle"
              fill="white"
            >
              ${totalNetAssetValue?.toLocaleString('en-US', { maximumFractionDigits: 0 })}
            </text>
          </PieChart>
        </div>
      </div>
    </>
  );
};

export default AllocationChart;
