import React from 'react';

export default function Logo() {
  return (
    <svg width="34" height="30" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_288_1450" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="25">
        <path d="M28 0H0V25H28V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_288_1450)">
        <path
          d="M21.2267 11.374L28.0016 4.04641L26.4985 2.5979L20.5011 8.38176C18.2135 10.5885 18.2135 14.1656 20.5011 16.3724L26.4985 22.1579L28 20.7093L21.2291 13.3801C20.6998 12.8089 20.6998 11.9452 21.2291 11.374H21.2267Z"
          fill="white"
        />
        <path
          d="M9.84206 8.3802L3.84464 2.59637L2.34155 4.04488L9.11643 11.3725C9.64494 11.9437 9.64494 12.8073 9.11643 13.3786L2.34548 20.7079L3.847 22.1563L9.84441 16.3708C12.132 14.1641 12.132 10.5869 9.84441 8.3802H9.84206Z"
          fill="white"
        />
        <path
          d="M11.0302 17.5162L5.03281 23.3018L6.53432 24.7503L14.1322 18.2184C14.7243 17.7083 15.6196 17.7083 16.2117 18.2184L23.8072 24.7537L25.3087 23.3035L19.3129 17.518C17.0253 15.3111 13.3178 15.3111 11.0302 17.518V17.5162Z"
          fill="white"
        />
        <path
          d="M19.3129 7.23575L25.3087 1.45021L23.8072 0L16.2117 6.5353C15.6196 7.04533 14.7243 7.04533 14.1322 6.5353L6.53432 0.00170013L5.03281 1.45021L11.0302 7.23575C13.3178 9.4425 17.0253 9.4425 19.3129 7.23575Z"
          fill="white"
        />
      </g>
    </svg>
  );
}
