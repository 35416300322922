// rootReducer.js
import { combineReducers } from 'redux';
import statBoxesReducer from '../state-slices/statBoxesSlice';
import allocationChartReducer from '../state-slices/allocationChartSlice';
import riskMetricsChartReducer from '../state-slices/riskMetricsChartSlice';
import performanceChartReducer from '../state-slices/performanceChartSlice';

const dashboardReducer = combineReducers({
  statBoxes: statBoxesReducer,
  allocationChart: allocationChartReducer,
  performanceChart: performanceChartReducer,
  riskMetricsChart: riskMetricsChartReducer,
});

export default dashboardReducer;
